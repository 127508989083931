import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ScreenAlert from '../../../components/common/ScreenAlert';

const SuccessPage: React.FC = () => {

    return (
        <PageProvider>
            <ScreenAlert iconType={5}/>
        </PageProvider>
    );
};

export default SuccessPage;
