import React, { useState, useEffect } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserInfo, createDeepLink, verifyDeepLink, saveSensitiveData } from '../../../actions/registerAction';
import { tokenSelector } from '../../../reducers/tokenReducer'
import { registerSelector } from '../../../reducers/registerReducer'
import { RootState } from '../../../store/store'
import { getOSByPlatform } from '../../../utils/getOSByPlatform'
import CustomInput from "../../../components/common/CustomInput";
import { formatIdCard, formatMobileNumber, formatReferral } from "../../../utils/formatInput";
import ReCAPTCHAMessage from '../components/ReCAPTCHAMessage';

const InfoPage: React.FC = () => {
  const dispatch = useDispatch()
  const { lksToken } = useSelector((state: RootState) => tokenSelector(state));
  const { authRef, registerRef, idCard, mobileNumber, referral } = useSelector((state: RootState) => registerSelector(state));
  const [cid, setCID] = useState<string>(idCard);
  const [phoneNumber, setPhoneNumber] = useState<string>(mobileNumber);
  const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false')
  const [recAcc, setRecAcc] = useState<string>(referral);
  const [isAllValid, setIsAllValid] = useState(false)
  const [isFirstTimeIdCard, setIsFirstTimeIdCard] = useState(
    () => JSON.parse(sessionStorage.getItem('isFirstTimeIdCard') || 'true')
  );

  const [isFirstTimeMobileNo, setIsFirstTimeMobileNo] = useState(
    () => JSON.parse(sessionStorage.getItem('isFirstTimeMobileNo') || 'true')
  );

  useEffect(() => {
    if (registerRef) {
      if (isHaveKMA) {
        dispatch(createDeepLink(lksToken, registerRef))
      }
    }
  }, [registerRef])

  useEffect(() => {
    if (authRef) {
      dispatch(verifyDeepLink(lksToken, registerRef, authRef))
    }
  }, [authRef])

  useEffect(() => {
    checkIsCanGoNext()
  }, [])

  const navigate = useNavigate();

  const goToNextPage = () => {
    dispatch(saveUserInfo(cid.replace(/-/g, ''), phoneNumber.replace(/-/g, ''), recAcc, getOSByPlatform(), false))
  };

  const goToPreviousPage = () => {
    dispatch(saveSensitiveData(cid, phoneNumber, recAcc));
    navigate(-1);
  };

  const validateIdCard = (value: string) => {
    if (isFirstTimeIdCard) {
      setIsFirstTimeIdCard(false);
      sessionStorage.setItem('isFirstTimeIdCard', JSON.stringify(false));
      return null;
    }
    if (!value) return 'กรุณากรอกหมายเลขบัตรประชาชน';
    if (value.replace(/\D/g, '').length !== 13) return 'กรุณากรอกหมายเลขบัตรประชาชน 13 หลักให้ถูกต้อง';
    return null;
  };

  const validateMobileNumber = (value: string) => {
    if (isFirstTimeMobileNo) {
      setIsFirstTimeMobileNo(false);
      sessionStorage.setItem('isFirstTimeMobileNo', JSON.stringify(false));
      return null;
    }
    if (!value) return 'กรุณากรอกเบอร์มือถือ';
    if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
    return null;
  };

  const checkIsCanGoNext = () => {
    const idCardError = validateIdCard(cid);
    const mobileNumberError = validateMobileNumber(phoneNumber);
    setIsAllValid(!idCardError && !mobileNumberError);
  };

  const inputFields = [
    {
      title: 'หมายเลขบัตรประชาชน',
      placeholder: 'ตัวอย่าง: 1-2345-67890-12-3',
      value: cid,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCID(e.target.value),
      type: 'tel' as 'tel',
      validate: validateIdCard,
      format: formatIdCard,
      onBlur: checkIsCanGoNext
    },
    {
      title: 'เบอร์มือถือ',
      subtitle: 'เบอร์ที่เคยให้กับธนาคารกรุงศรี',
      placeholder: 'ตัวอย่าง: 088-123-4567',
      value: phoneNumber,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value),
      type: 'tel' as 'tel',
      validate: validateMobileNumber,
      format: formatMobileNumber,
      onBlur: checkIsCanGoNext
    },
    {
      title: 'รหัสผู้แนะนำ (ถ้ามี)',
      placeholder: 'รหัสผู้แนะนำบริการ',
      value: recAcc,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setRecAcc(e.target.value),
      type: 'text' as 'text',
      format: formatReferral,
    },
  ];

  const handleSubmit = () => {
    console.log("isFirstTimeIdCard", isFirstTimeIdCard)
    console.log("isAllvalid BB", isAllValid)
  };

  return (
    <PageProvider>
      <Title style={{ marginBottom: 8 }}>ข้อมูลผู้สมัคร</Title>
      {inputFields.map((field, index) => (
        <CustomInput
          key={index}
          title={field.title}
          subtitle={field.subtitle}
          placeholder={field.placeholder}
          value={field.value}
          onChange={field.onChange}
          type={field.type}
          validate={field.validate}
          format={field.format}
          onBlur={field.onBlur}
        />
      ))}
      {/* <button
        onClick={handleSubmit}
        style={{
          marginTop: '16px',
          padding: '8px 16px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Log Values
      </button> */}
      <ButtomSpan>
        <Button text={'ถัดไป'} type={isAllValid ? 0 : 1} onClick={goToNextPage} />
        <div style={{ marginBottom: 24 }} />
        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
        <ReCAPTCHAMessage/>
      </ButtomSpan>
    </PageProvider>
  );
};

export default InfoPage;
