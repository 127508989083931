export const ADD_PRODUCT = 'ADD_PRODUCT'
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const GET_SURVEYS_LIST = 'GET_SURVEYS_LIST'
export const SET_SURVEYS_LIST = 'SET_SURVEYS_LIST'
export const SAVE_SURVEYS_LIST = 'SAVE_SURVEYS_LIST'

export const addProduct = (accountNumber: string, oldBankList: any[]) => ({
  type: ADD_PRODUCT,
  payload: {
    accountNumber,
    oldBankList,
  },
});

export const setBankAccounts = (bankAccounts: any[]) => ({
  type: SET_BANK_ACCOUNTS,
  payload: bankAccounts,
});

export const getSurveysList = () => {
  return {
    type: GET_SURVEYS_LIST
  }
}

export const setSurveysList = (survey: { id: string; name: string; type: number }[]) => {
  return {
    type: SET_SURVEYS_LIST,
    payload: { survey },
  }
};

export const saveSurveysList = (answers: string[]) => ({
  type: SAVE_SURVEYS_LIST,
  payload: { answers },
});