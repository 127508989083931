import { RootState } from '../store/store';
import { createSelector } from 'reselect';
import {
  SET_ACCOUNT_DETAIL,
} from '../actions/statementAction';

export interface AccountState {
  accountData: {
    accountNumber: string;
    accountType: string;
    email: string;
  };
}

export interface AccountAction {
  type: string;
  payload: {
    accountData: {
      accountNumber: string;
      accountType: string;
      email: string;
    };
  };
}

const initialState: AccountState = {
  accountData: {
    accountNumber: '',
    accountType: '',
    email: ''
  },
};

export const accountReducer = (
  state: AccountState = initialState,
  action: AccountAction
): AccountState => {
  switch (action.type) {
    case SET_ACCOUNT_DETAIL:
      return {
        ...state,
        accountData: action.payload.accountData,
      };
    default:
      return state;
  }
};

const selectAccount = (state: RootState) => state.account;

export const accountSelector = createSelector(
  [selectAccount],
  (account) => ({
    accountData: account?.accountData ?? [],
  })
);
