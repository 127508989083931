import React from 'react';
import Colors from '../../../assets/styles/Colors';
import { Description } from '../Texts';
import Button from '../Button';

interface PopupAlertProps {
  isVisible?: boolean;
  iconType?: number;
  title: string;
  description: string;
  ButtonClick: () => void;
}

const iconSources: { [key: number]: string } = {
  0: '',
  1: '/svg/alert.svg',
  2: '/svg/suspend.svg',
};

const PopupAlert: React.FC<PopupAlertProps> = ({
  isVisible = true,
  iconType = 1,
  title,
  description,
  ButtonClick,
}) => {
  if (!isVisible) return null;

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(89, 89, 89, 0.5)',
        zIndex: 1000,
    }}>
        <div style={{
            backgroundColor: '#fff',
            paddingTop: '40px',
            paddingBottom: '40px',
            width: '100%',
            textAlign: 'center',
        }}>
            {iconSources[iconType] && <img src={iconSources[iconType]} alt="Popup Icon" style={{
                width: '88px',
                height: '88px',
                marginBottom: '40px',
            }} />}

            <div style={{
                color: Colors.MAIN_TEXT_COLOR,
                fontFamily: 'Krungsri_Bold',
                fontSize: '24px',
                lineHeight: '36px',
            }}>
                {title}
            </div>

            <div style={{
                marginTop: 8,
                width: 343,
                textAlign: 'center',
                margin: '0 auto',
                whiteSpace: 'pre-line',
            }}>
                <Description>{description}</Description>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 40,
            }}>
                <Button text="ตกลง" onClick={ButtonClick} type={0} />
            </div>
        </div>
    </div>
);
};

export default PopupAlert;
