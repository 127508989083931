import { call, put, take } from 'redux-saga/effects';
import { navigationRef } from '../utils/navigation';
import {
  ACCEPT_TERMS_AND_CONDITIONS,
  CREATE_DEEP_LINK,
  FETCH_TERMS_AND_CONDITIONS,
  SAVE_USER_INFO,
  SUBMIT_REGISTER,
  VERIFY_DEEP_LINK,
  GENERATE_OTP,
  setAuthenticationReference,
  setBankAccounts,
  setRegisterReference,
  setTermsAndConditions,
  setOtpRef,
  saveSensitiveData,
  VERIFY_OTP,
} from '../actions/registerAction';
import {
  apiGetTermsAndConditions,
  apiAcceptTermsAndConditions,
  apiSaveUserInfo,
  apiCreateDeepLink,
  apiVerifyDeepLink,
  apiSubmitRegister,
  apiGenerateOtp,
  apiVerifyOtp,
  apiGenerateOtpForStatement,
  apiVerifyOtpForStatement,
} from '../api/api';
import { showSpinner, hideSpinner } from '../components/common/AppProvider';
import { handleStatusError } from './rootSaga';

function* workerFetchTermsAndConditions(): Generator<any, void, any> {
  try {
    yield call(showSpinner);
    const response = yield call(apiGetTermsAndConditions);
    if (response.success) {
      yield put(setTermsAndConditions(
        response.data.content,
        response.data.id,
      ));
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherFetchTermsAndConditions(): Generator<any, void, any> {
  while (true) {
    const action = yield take(FETCH_TERMS_AND_CONDITIONS);
    yield call(workerFetchTermsAndConditions);
  }
}

function* workerAcceptTermsAndConditions(payload: any): Generator<any, void, any> {
  try {
    yield call(showSpinner);
    const response = yield call(apiAcceptTermsAndConditions, payload.id);
    if (response.success) {
      if (navigationRef.current) {
        navigationRef.current('/verify');
      } else {
        yield call(handleStatusError, '404', 'navigationRef.current is not set');
      }
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherAcceptTermsAndConditions(): Generator<any, void, any> {
  while (true) {
    const action = yield take(ACCEPT_TERMS_AND_CONDITIONS);
    yield call(workerAcceptTermsAndConditions, action.payload);
  }
}

function* workerSaveUserInfo(payload: any): Generator<any, void, any> {
  try {
    const {identificationNumber, mobileNumber, referral, deviceOS, isResend } = payload
    const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false')
    yield call(showSpinner);
    yield put(saveSensitiveData(identificationNumber, mobileNumber, referral));
    const response = yield call(
      apiSaveUserInfo,
      identificationNumber,
      mobileNumber,
      referral,
      deviceOS,
    );
    if (response.success) {
      sessionStorage.setItem('registerRef', response.data.registerRef);
      if (isHaveKMA) {
        // 
      } else {
        yield call(workerGenerateOtp, {otpData: {flow:"register", isResend: isResend}});
      }
      yield put(setRegisterReference(
        response.data.registerRef,
        response.data.mustConfirmRegisterForReplace,
      ));
    } else {
      yield call(hideSpinner);
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  }
}

export function* watcherSaveUserInfo(): Generator<any, void, any> {
  while (true) {
    const action = yield take(SAVE_USER_INFO);
    yield call(workerSaveUserInfo, action.payload);
  }
}

function* workerCreateDeepLink(payload: any): Generator<any, void, any> {
  try {
    yield call(showSpinner);
    const response = yield call(apiCreateDeepLink)
    if (response.success) {
      yield put(setAuthenticationReference(
        response.data.authenticationReference,
        response.data.authenticationWebUri,
        response.data.authenticationApplicationUri,
        response.data.expiryDateTime,
      ))
      // window.location.href = response.data.authenticationApplicationUri;
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  }
}

export function* watcherCreateDeepLink(): Generator<any, void, any> {
  while (true) {
    const action = yield take(CREATE_DEEP_LINK);
    yield call(workerCreateDeepLink, action.payload);
  }
}


function* workerVerifyDeepLink(payload: any): Generator<any, void, any> {
  try {
    const response = yield call(apiVerifyDeepLink, payload.authRef)
    if (response.success) {
      const bankAccountsWithStatus = response.data.bankAccounts.map((account: any) => ({
        accountNumber: account.accountNumber,
        type: account.accountType,
        isNotificationEnabled: true,
        isFromKMA: true
      }));
      yield put(setBankAccounts(bankAccountsWithStatus))
      yield call(hideSpinner);
      if (navigationRef.current) {
        navigationRef.current('/confirm-account');
      } else {
        yield call(handleStatusError, '404', 'navigationRef.current is not set');
      }
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  }
}

export function* watcherVerifyDeepLink(): Generator<any, void, any> {
  while (true) {
    const action = yield take(VERIFY_DEEP_LINK);
    yield call(workerVerifyDeepLink, action.payload);
  }
}

function* workerGenerateOtp(payload: any): Generator<any, void, any> {
  const {flow, mobileNumber, isResend} = payload.otpData
  if (mobileNumber) {
    yield put(saveSensitiveData('', mobileNumber,''));
  }
  try {
    yield call(showSpinner);
    let response;
    if (flow === 'register') {
      response = yield call(apiGenerateOtp)
    } else if (flow === 'statement') {
      response = yield call(apiGenerateOtpForStatement,mobileNumber)
    } 
    if (response.success) {
      yield put(setOtpRef(response.data.otpRef))
      if (!isResend) {
        if (navigationRef.current) {
          navigationRef.current('/verify-otp-page');
        } else {
          yield call(handleStatusError, '404', 'navigationRef.current is not set');
        }
      }
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGenerateOtp(): Generator<any, void, any> {
  while (true) {
    const action = yield take(GENERATE_OTP);
    yield call(workerGenerateOtp, action.payload);
  }
}

function* workerVerifyOtp(payload: any): Generator<any, void, any> {
  const {flow, otpRef, otpCode} = payload.verifyBy

  try {
    yield call(showSpinner);
    let response;
    if (flow === 'register') {
      response = yield call(apiVerifyOtp,otpRef,otpCode)
    } else if (flow === 'statement') {
      response = yield call(apiVerifyOtpForStatement,otpRef,otpCode)
    } 
    if (response.success) {
      if (navigationRef.current) {
        if (flow === 'register') {
          navigationRef.current('/add-account');
        } else {
          navigationRef.current('/select-statement');
        }
      } else {
        yield call(handleStatusError, '404', 'navigationRef.current is not set');
      }
      yield call(hideSpinner);
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherVerifyOtp(): Generator<any, void, any> {
  while (true) {
    const action = yield take(VERIFY_OTP);
    yield call(workerVerifyOtp, action.payload);
  }
}


function* workerSubmitRegister(payload: any): Generator<any, void, any> {
  try {
    yield call(showSpinner);
    const response = yield call(apiSubmitRegister, payload.registerAccounts);
    if (response.success) {
      if (navigationRef.current) {
        navigationRef.current('/survey');
      } else {
        yield call(handleStatusError, '404', 'navigationRef.current is not set');
      }
    } else {
      yield call(handleStatusError, response.statusCode, response.message);
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSubmitRegister(): Generator<any, void, any> {
  while (true) {
    const action = yield take(SUBMIT_REGISTER);
    yield call(workerSubmitRegister, action.payload);
  }
}