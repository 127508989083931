const Colors = {
    BACKGROUND_DISABLE: '#F0EFEE',
    MAIN_TEXT_COLOR: '#222222',
    PRIMARY_KS_YELLOW: '#FFD400',
    PRIMARY_TEXT_COLOR: '#5A5A5A',
    SECONDARY_BROWN: '#554242',
    SECONDARY_TEXT_COLOR: '#949494',
    STROKE_NORMAL: '#E8E8E8',
    
    WHITE: '#FFF',
    GREY: '#BBBBBB',
    ORANGE: '#F76F00',
    RED: '#D00000'
};

export default Colors;