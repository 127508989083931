export const FETCH_TERMS_AND_CONDITIONS = 'FETCH_TERMS_AND_CONDITIONS';
export const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS';
export const ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS';
export const SAVE_SENSITIVE_DATA = 'SAVE_SENSITIVE_DATA';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const SET_REGISTER_REFERENCE = 'SET_REGISTER_REFERENCE';
export const CREATE_DEEP_LINK = 'CREATE_DEEP_LINK';
export const SET_AUTHENTICATION_REFERENCE = 'SET_AUTHENTICATION_REFERENCE';
export const VERIFY_DEEP_LINK = 'VERIFY_DEEP_LINK';
export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const SUBMIT_REGISTER = 'SUBMIT_REGISTER';
export const GENERATE_OTP = "GENERATE_OTP"
export const SET_OTP_REFERENCE = "SET_OTP_REFERENCE"
export const VERIFY_OTP = "VERIFY_OTP"

export const fetchTermsAndConditions = () => ({
  type: FETCH_TERMS_AND_CONDITIONS,
});

export const setTermsAndConditions = (
  terms: string,
  id: string,
) => ({
  type: SET_TERMS_AND_CONDITIONS,
  payload: {
    terms,
    id,
  }
});

export const acceptTermsAndConditions = (id: string) => ({
  type: ACCEPT_TERMS_AND_CONDITIONS,
  payload: { id },
});

export const saveSensitiveData = (idCard: string, mobileNumber: string, referral: string) => ({
  type: SAVE_SENSITIVE_DATA,
  payload: { idCard, mobileNumber, referral },
});

export const saveUserInfo = (identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string, isResend: boolean) => ({
  type: SAVE_USER_INFO,
  payload: {
    identificationNumber,
    mobileNumber,
    referral,
    deviceOS,
    isResend,
  }
})

export const setRegisterReference = (
  registerRef: string,
  mustConfirmRegisterForReplace: boolean,
) => ({
  type: SET_REGISTER_REFERENCE,
  payload: {
    registerRef,
    mustConfirmRegisterForReplace,
  }
});

export const createDeepLink = (
  lksToken: string,
  registerRef: string,
) => ({
  type: CREATE_DEEP_LINK,
  payload: {
    lksToken,
    registerRef,
  }
});

export const generateOtp = (otpData: {flow: string, mobileNumber: string, isResend: boolean}) => {
  return {
    type: GENERATE_OTP,
    payload: { otpData }
  }
}

export const setOtpRef = (otpRef: string) => {
  return {
    type: SET_OTP_REFERENCE,
    payload: { otpRef }
  }
}

export const verifyOtp = (verifyBy: {flow: string, otpRef: string, otpCode: string}) => {
  return {
    type: VERIFY_OTP,
    payload: { verifyBy }
  }
}

export const setAuthenticationReference = (
  authRef: string,
  authenticationWebUri: string,
  authenticationApplicationUri: string,
  expiryDateTime: string,
) => ({
  type: SET_AUTHENTICATION_REFERENCE,
  payload: {
    authRef,
    authenticationWebUri,
    authenticationApplicationUri,
    expiryDateTime,
  }
});

export const verifyDeepLink = (
  lksToken: string,
  registerRef: string,
  authRef: string,
) => ({
  type: VERIFY_DEEP_LINK,
  payload: {
    lksToken,
    registerRef,
    authRef,
  }
});

export const setBankAccounts = (bankAccounts: { accountNumber: string; type: string, isNotificationEnabled: boolean }[]) => {
  return {
    type: SET_BANK_ACCOUNTS,
    payload: { bankAccounts },
  }
};

interface BankAccount {
  accountNumber: string;
  type: string;
}

export const submitRegister = (
  registerAccounts: BankAccount[],
) => {
  return {
    type: SUBMIT_REGISTER,
    payload: { registerAccounts }
  }
}

