import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import RenderCards from '../components/RenderCards';

const VerifyPage: React.FC = () => {

    const navigate = useNavigate();

    const goToNextPageWithKMA = () => {
        sessionStorage.setItem('isHaveKMA', JSON.stringify(true));
        navigate('/info');
    };

    const goToNextPageWithOTP = () => {
        sessionStorage.setItem('isHaveKMA', JSON.stringify(false));
        navigate('/info');
    };

    const items = [
        {
            subtitle: 'สิ่งที่ต้องเตรียม',
            description: 'หมายเลขบัตรประชาชน',
            image: 'idcard',
        },
        {
            description: 'เบอร์มือถือที่เคยให้กับธนาคารกรุงศรี',
            image: 'mobile',
        },
        {
            description: 'เลขที่บัญชี กรณีไม่มี krungsri app',
            image: 'kol',
        },
    ];

    return (
        <PageProvider>
            <Title style={{ marginBottom: 16 }}>ยืนยันตัวตนเพื่อสมัครบริการ</Title>
            <RenderCards items={items} />
            <ButtomSpan>
                <Button text={'ยืนยันด้วย krungsri app'} type={0} onClick={goToNextPageWithKMA} />
                <div style={{ marginBottom: 24 }} />
                <Button text={'ไม่มี krungsri app'} type={2} onClick={goToNextPageWithOTP} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default VerifyPage;
