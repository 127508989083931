import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import AppRoutes from './routes/AppRoutes';
import { AppProvider } from './components/common/AppProvider';
import './index.css'

function App() {
  return (
    <Provider store={store}>
      <AppProvider>
          <Router>
            <AppRoutes />
          </Router>
      </AppProvider>
    </Provider>
  );
}

export default App;