import React from "react";

const Header = () => (
    <header>
        <div style={{ display: 'flex' }}>
            <img
                alt="BAY Logo"
                src="/svg/bayLogo.svg"
                style={{
                    height: 48,
                    margin: '4px 0px 4px 8px',
                    width: 104,
                }}
            />
        </div>
        <div
            style={{
                background: 'linear-gradient(to bottom, #f1f1f1, #ffffff)',
                height: 4,
                width: "100%",
            }}>
        </div>
    </header>
);

export default Header;
