import React from 'react';
import Colors from '../../assets/styles/Colors';

interface CheckButtonProps {
  size: number;
  checked: boolean;
  onChange: () => void;
}

export default function CheckButton({ checked, onChange, size }: CheckButtonProps) {
  return (
    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          position: 'absolute',
          opacity: 0,
          cursor: 'pointer',
          height: 0,
          width: 0,
        }}
      />
      <span style={
        {
          border: checked ? `1px solid ${Colors.PRIMARY_KS_YELLOW}` : `1px solid ${Colors.GREY}`,
          backgroundColor: checked ? Colors.PRIMARY_KS_YELLOW : Colors.WHITE,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: size,
          height: size,
          position: 'relative',
          marginRight: '8px',
        }
      }>
        <span style={
          {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: Colors.WHITE,
            display: checked ? 'block' : 'none',
          }
        }></span>
      </span>
    </label>
  );
};
