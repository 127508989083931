import React, { useState, useEffect, createContext, useContext } from 'react';
import EventEmitter from 'eventemitter3';
import Spinner from './Spinner';
import PopupAlert from './popup/PopUpAlert';
import PopupValid from './popup/PopupValid';
import PopupVerify from './popup/PopupVerify';

const dialogEvent = new EventEmitter();

export const showSpinner = () => {
    dialogEvent.emit('show_spinner');
};

export const hideSpinner = () => {
    dialogEvent.emit('hide_spinner');
};

export const showPopupAlert = (config: {
    title: string;
    subtitle: string;
    description: string;
    iconType?: number;
    ButtonText?: string;
    ButtonClick?: () => void;
}) => {
    dialogEvent.emit('show_popup_alert', config);
};

export const hidePopupAlert = () => {
    dialogEvent.emit('hide_popup_alert');
};

export const showPopupValid = (config: { title: string }) => {
    dialogEvent.emit('show_popup_alert_valid', config);
    setTimeout(() => {
        dialogEvent.emit('hide_popup_alert_valid');
    }, 2000);
};

export const hidePopupValid = () => {
    dialogEvent.emit('hide_popup_alert_valid');
};

export const showPopupVerify = (config: {
    title: string;
    description: string;
    primaryButtonText: string;
    primaryButtonClick: () => void;
    secondaryButtonClick: () => void;
}) => {
    dialogEvent.emit('show_popup_alert_verify', config);
};

export const hidePopupVerify = () => {
    dialogEvent.emit('hide_popup_alert_verify');
};

const AppContext = createContext({
    showSpinner: () => { },
    hideSpinner: () => { },
    showPopupAlert: (config: any) => { },
    hidePopupAlert: () => { },
    showPopupValid: (config: any) => { },
    hidePopupValid: () => { },
    showPopupVerify: (config: any) => { },
    hidePopupVerify: () => { },
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [popupAlert, setPopupAlert] = useState<{
        isVisible: boolean;
        title: string;
        subtitle: string;
        description: string;
        iconType?: number;
        ButtonText?: string;
        ButtonClick?: () => void;
    }>({ isVisible: false, title: '', subtitle: '', description: '' });

    const [popupValid, setPopupValid] = useState<{
        isVisible: boolean;
        title: string;
    }>({ isVisible: false, title: '' });

    const [popupVerify, setPopupVerify] = useState<{
        isVisible: boolean;
        title: string;
        description: string;
        primaryButtonText: string;
        primaryButtonClick: () => void;
        secondaryButtonClick: () => void;
    }>({ isVisible: false, title: '', description: '', primaryButtonText: '', primaryButtonClick: () => {}, secondaryButtonClick: () => {} });

    useEffect(() => {
        const showSpinnerHandler = () => setSpinnerVisible(true);
        const hideSpinnerHandler = () => setSpinnerVisible(false);

        const showPopupAlertHandler = (config: any) => setPopupAlert({ ...config, isVisible: true });
        const hidePopupAlertHandler = () => setPopupAlert((prev) => ({ ...prev, isVisible: false }));

        const showPopupValidHandler = (config: any) => setPopupValid({ ...config, isVisible: true });
        const hidePopupValidHandler = () => setPopupValid((prev) => ({ ...prev, isVisible: false }));

        const showPopupVerifyHandler = (config: any) => setPopupVerify({ ...config, isVisible: true });
        const hidePopupVerifyHandler = () => setPopupVerify((prev) => ({ ...prev, isVisible: false }));

        dialogEvent.on('show_spinner', showSpinnerHandler);
        dialogEvent.on('hide_spinner', hideSpinnerHandler);
        dialogEvent.on('show_popup_alert', showPopupAlertHandler);
        dialogEvent.on('hide_popup_alert', hidePopupAlertHandler);
        dialogEvent.on('show_popup_alert_valid', showPopupValidHandler);
        dialogEvent.on('hide_popup_alert_valid', hidePopupValidHandler);
        dialogEvent.on('show_popup_alert_verify', showPopupVerifyHandler);
        dialogEvent.on('hide_popup_alert_verify', hidePopupVerifyHandler);

        return () => {
            dialogEvent.off('show_spinner', showSpinnerHandler);
            dialogEvent.off('hide_spinner', hideSpinnerHandler);
            dialogEvent.off('show_popup_alert', showPopupAlertHandler);
            dialogEvent.off('hide_popup_alert', hidePopupAlertHandler);
            dialogEvent.off('show_popup_alert_valid', showPopupValidHandler);
            dialogEvent.off('hide_popup_alert_valid', hidePopupValidHandler);
            dialogEvent.off('show_popup_alert_verify', showPopupVerifyHandler);
            dialogEvent.off('hide_popup_alert_verify', hidePopupVerifyHandler);
        };
    }, []);

    return (
        <AppContext.Provider value={{
            showSpinner,
            hideSpinner,
            showPopupAlert,
            hidePopupAlert,
            showPopupValid,
            hidePopupValid,
            showPopupVerify,
            hidePopupVerify,
        }}>
            {children}
            {spinnerVisible && <Spinner isVisible={spinnerVisible} />}
            {popupAlert.isVisible && (
                <PopupAlert
                    isVisible={popupAlert.isVisible}
                    title={popupAlert.title}
                    description={popupAlert.description}
                    iconType={popupAlert.iconType ?? 1}
                    ButtonClick={popupAlert.ButtonClick ?? (() => hidePopupAlert())}
                />
            )}
            {popupValid.isVisible && (
                <PopupValid
                    isVisible={popupValid.isVisible}
                    title={popupValid.title}
                />
            )}
            {popupVerify.isVisible && (
                <PopupVerify
                    isVisible={popupVerify.isVisible}
                    title={popupVerify.title}
                    description={popupVerify.description}
                    primaryButtonText={popupVerify.primaryButtonText}
                    primaryButtonClick={popupVerify.primaryButtonClick}
                    secondaryButtonClick={popupVerify.secondaryButtonClick}
                />
            )}
        </AppContext.Provider>
    );
};

// Custom hook to use AppContext
export const useAppContext = () => useContext(AppContext);
