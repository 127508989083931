import React, { useEffect, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title, SubTitle, Description } from '../../../components/common/Texts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import liff from '@line/liff';
import { useDispatch } from 'react-redux';
import { exchangeLineToken } from '../../../actions/tokenAction';
import useLineIdToken from '../../../hooks/useLineIdToken';

const PreStatementPage: React.FC = () => {

    const dispatch = useDispatch();
    const lineIdToken = useLineIdToken()

    const handleAlertClick = () => {
        const aa = sessionStorage.getItem('accountRef')
        const fullUrl = window.location.href;
        alert(`Current URL: ${fullUrl}
            ---------------------------
            accountRef: ${JSON.stringify(aa)}`);
      };


    const goToNextPageWithKMA = () => {
        sessionStorage.setItem('requestStatementByKMA', JSON.stringify(true));
        dispatch(exchangeLineToken(lineIdToken, '/select-statement'));
    };

    const goToNextPageWithOTP = () => {
        sessionStorage.setItem('requestStatementByOTP', JSON.stringify(true));
        dispatch(exchangeLineToken(lineIdToken, '/receive-otp'));
    };

    return (
        <PageProvider>
            <div style={{
                marginTop: 217,
                textAlign: 'center',
                // border: '1.5px solid #39bd02',
            }}>
                <img
                    onClick={handleAlertClick}
                    src={'/svg/bayIcon.svg'}
                    alt="bay icon"
                    style={{
                        width: 56, 
                        height: 56,
                        flexShrink: 0,
                        marginBottom: 24,
                    }}
                />
                <Title style={{fontSize: 18, lineHeight:'30px'}}>กรุณายืนยันตัวตนเพื่อทำรายการ</Title>
            </div>

            <ButtomSpan>
                <Button text={'ยืนยันด้วย krungsri app'} type={0} onClick={goToNextPageWithKMA} />
                <div style={{ marginBottom: 24 }} />
                <Button text={'ไม่มี krungsri app'} type={2} onClick={goToNextPageWithOTP} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default PreStatementPage;
