import React, { useState, useRef, useEffect } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import CheckBox from '../../../components/common/CheckBox';
import { Title } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTermsAndConditions, acceptTermsAndConditions } from '../../../actions/registerAction';
import { tokenSelector } from '../../../reducers/tokenReducer'
import { registerSelector } from '../../../reducers/registerReducer'
import { RootState } from '../../../store/store'

const TermsAndConditionsPage: React.FC = () => {

  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);

  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(
    () => JSON.parse(sessionStorage.getItem('isCheckboxEnabled') || 'false')
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(
    () => JSON.parse(sessionStorage.getItem('isCheckboxChecked') || 'false')
  );

  const { terms, id } = useSelector((state: RootState) => registerSelector(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTermsAndConditions());
  }, [dispatch]);

  const goToNextPage = () => {
    dispatch(acceptTermsAndConditions(id));
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    const element = contentRef.current;

    const handleScroll = () => {
      if (element) {
        const bottomReached = element.scrollTop + element.clientHeight >= element.scrollHeight - 10;
        if (bottomReached) {
          setIsCheckboxEnabled(true);
          sessionStorage.setItem('isCheckboxEnabled', JSON.stringify(true));
        }
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const element = contentRef.current;

    if (element && isCheckboxEnabled) {
      if (element.scrollHeight > element.clientHeight) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }, [isCheckboxEnabled]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setIsCheckboxChecked(isChecked);
    sessionStorage.setItem('isCheckboxChecked', JSON.stringify(isChecked));
  };

  return (
    <PageProvider>
      <Title style={{ marginBottom: 16 }}>ข้อกำหนดและเงื่อนไข</Title>
      <div
        ref={contentRef}
        style={{
          width: '100%',
          height: '45vh',
          marginBottom: '16px',
          overflowY: 'scroll',
          boxSizing: 'border-box',
        }}
      >
        {terms && (
          <div dangerouslySetInnerHTML={{ __html: terms }} />
        )}
      </div>
        <CheckBox
          checked={isCheckboxChecked}
          disabled={!isCheckboxEnabled}
          onChange={handleCheckboxChange}
          text={'ยอมรับข้อกำหนดและเงื่อนไข'}
        />

      <ButtomSpan>
        <Button text={'ถัดไป'} type={isCheckboxChecked ? 0 : 1} onClick={goToNextPage} />
        <div style={{ marginBottom: 24 }} />
        <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
      </ButtomSpan>
    </PageProvider>
  );
};

export default TermsAndConditionsPage;

