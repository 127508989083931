import React from 'react';
import { Description, SubTitle } from '../../../components/common/Texts';

type Item = {
  subtitle?: string;
  description: string;
  image: string;
  onClickHandler?: () => void;
};

const RenderCards: React.FC<{ items: Item[] }> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <div
          key={index}
          style={{ marginBottom: index === items.length - 1 ? 0 : 24 }}
        >
          {item.subtitle && <SubTitle style={{ marginBottom: 8 }}>{item.subtitle}</SubTitle>}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '58px',
              // border: '1.5px solid #f2ad68'
            }}
          >
            <img
              onClick={item.onClickHandler}
              src={`/svg/${item.image}.svg`}
              alt={`${item.image} logo`}
              style={{
                width: '56px',
                height: '56px',
                // border: '1.5px solid #39bd02'
              }}
            />
            <div style={{ marginLeft: 8, maxWidth: 254 }}>
              <Description>{item.description}</Description>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default RenderCards;
