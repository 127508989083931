import React, { useState, ChangeEvent } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import Button from '../../../components/common/Button';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { Title } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { addProduct } from '../../../actions/accountAction';
import { registerSelector } from "../../../reducers/registerReducer";
import { RootState } from "../../../reducers/rootReducer";
import CustomInput from "../../../components/common/CustomInput";
import { formatAccountNumber } from "../../../utils/formatInput";
import PopupAlert from "../../../components/common/popup/PopUpAlert";

export default function AddAccountPage() {
    const dispatch = useDispatch()
    const { bankAccounts } = useSelector((state: RootState) => registerSelector(state));
    const [accountID, setAccountID] = useState<string>("");
    const [isAccountIDValid, setIsAccountIDValid] = useState(false);
    const [iconType, setIconType] = useState<number>(1);
    const [popUpTitle, setPopupTitle] = useState<string>("");
    const [popUpDescription, setPopupDescription] = useState<string>("");
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();
    const handleAccountID = (e: ChangeEvent<HTMLInputElement>) => {
        setAccountID(e.target.value)
    };

    const validateAccountID = (value: string) => {
        if (!value) return 'กรุณากรอกเลขที่บัญชี';
        if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเลขที่บัญชี 10 หลักให้ถูกต้อง';
        return null;
    };

    const onClosePopup = () => {
        setShowPopup(false);
    }

    const goToNextPage = () => {
        dispatch(addProduct(accountID.replace(/-/g, ''), bankAccounts));
    };

    const goToPreviousPage = () => {
        navigate('/info');
    };

    const checkIsCanGoNext = () => {
        const addAccountError = validateAccountID(accountID);
        setIsAccountIDValid(!addAccountError)
    };

    return (
        <PageProvider>
            <Title style={{ marginBottom: 8 }}>กรอกข้อมูลผลิตภัณฑ์</Title>
            <CustomInput
                title='เลขที่บัญชี'
                placeholder='ตัวอย่าง: 123-4-51234-1'
                value={accountID}
                onChange={handleAccountID}
                type='tel'
                validate={validateAccountID}
                format={formatAccountNumber}
                onBlur={checkIsCanGoNext}
                validateOnInit={false}
            />
            <ButtomSpan>
                <Button text={'เพิ่ม'} type={!isAccountIDValid ? 1 : 0} onClick={goToNextPage} /> 
                <div style={{ marginBottom: 24 }} />
                <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
            </ButtomSpan>
            {
                showPopup && <PopupAlert
                    title={popUpTitle}
                    iconType={iconType}
                    description={popUpDescription}
                    ButtonClick={onClosePopup}/>
            }
        </PageProvider>
    );
}