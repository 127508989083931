import React, { ReactNode } from 'react';
import AppHeader from "./AppHeader"

interface PageProviderProps {
  children: ReactNode;
}

const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  return (
    <div style={{
      margin: '0 auto',
      maxWidth: '450px',
      touchAction: 'pan-x pan-y',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <AppHeader />
      <div style={{
        flexGrow: 1,
        marginBottom: '100px',
        padding: '16px',
        // border: '1.5px solid #ee2740',
      }}>
        {children}
      </div>
    </div>
  );
};

export default PageProvider;
