import { call, put, take } from 'redux-saga/effects';
import { navigationRef } from '../utils/navigation';
import { EXCHANGE_LINE_TOKEN, updateLksToken } from '../actions/tokenAction';
import { apiGetVerify } from '../api/api';
import { showSpinner, hideSpinner } from '../components/common/AppProvider';
import { handleStatusError } from './rootSaga';

function* workerExchangeLineToken(payload: any): Generator<any, void, any> {
  try {
    const { lineToken, nextPage } = payload
    yield call(showSpinner);
    if (lineToken) {
      const response = yield call(apiGetVerify, lineToken);
      if (response.success) {
        sessionStorage.setItem('lksToken', response.data.accessToken);
        if (navigationRef.current) {
          navigationRef.current(nextPage);
        } else {
          yield call(handleStatusError, '404', 'navigationRef.current is not set');
        }
      } else {
        yield call(handleStatusError, response.statusCode, response.message);
      }
    } else {
      yield call(handleStatusError, '404', 'lineToken is missing');
    }
  } catch (error) {
    yield call(handleStatusError, '404', JSON.stringify(error));
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherExchangeLineToken(): Generator<any, void, any> {
  while (true) {
    const action = yield take(EXCHANGE_LINE_TOKEN);
    yield call(workerExchangeLineToken, action.payload);
  }
}