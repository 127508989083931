import React from 'react';
import Colors from '../../../assets/styles/Colors';
import { Description } from '../Texts';
import Button from '../Button';

interface PopupVerifyProps {
  isVisible?: boolean;
  title: string;
  description: string;
  primaryButtonText: string;
  primaryButtonClick: () => void;
  secondaryButtonClick: () => void;
}

const PopupVerify: React.FC<PopupVerifyProps> = ({
  isVisible = true,
  title,
  description,
  primaryButtonText,
  primaryButtonClick,
  secondaryButtonClick,
}) => {
  if (!isVisible) return null;

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(89, 89, 89, 0.5)',
        zIndex: 1000,
    }}>
        <div style={{
            backgroundColor: '#fff',
            paddingTop: '40px',
            paddingBottom: '40px',
            width: '100%',
            textAlign: 'center',
        }}>

            <div style={{
                color: Colors.MAIN_TEXT_COLOR,
                fontFamily: 'Krungsri_Bold',
                fontSize: '24px',
                lineHeight: '36px',
            }}>
                {title}
            </div>

            <div style={{
                marginTop: 8,
                width: 343,
                textAlign: 'center',
                margin: '0 auto',
                whiteSpace: 'pre-line',
            }}>
                <Description>{description}</Description>
            </div>

            <div
                style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 40,
                }}
            >
                <Button text={primaryButtonText} onClick={primaryButtonClick} type={0} />

                <div style={{ marginTop: 16 }}>
                    <Button text="ยกเลิก" onClick={secondaryButtonClick} type={2} />
                </div>
            </div>
        </div>
    </div>
);
};

export default PopupVerify;
