import { combineReducers } from 'redux';
import { tokenReducer } from './tokenReducer';
import { registerReducer } from './registerReducer'
import { accountReducer } from './accountReducer';

const rootReducer = combineReducers({
  tokens: tokenReducer,
  register: registerReducer,
  account: accountReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
