import React, { useEffect, useMemo, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccountInfo from '../../../components/common/AccountInfo';
import Button from '../../../components/common/Button';
import { Description, Title } from '../../../components/common/Texts';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import ButtomSpan from "../../../components/layout/BottomSpan"
import { setBankAccounts } from '../../../actions/registerAction';
import Colors from '../../../assets/styles/Colors';

export default function AccountDetailPage() {
    const { account_no } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { bankAccounts } = useSelector((state: RootState) => registerSelector(state));
    const goToPreviousPage = () => {
        navigate(-1);
    };
    const isHaveLastAccount = bankAccounts.length === 1
    const [accounts, setAccounts] = useState(bankAccounts);
    const account = accounts.find((acc) => acc.accountNumber === account_no);

    function maskAccountNumber(accountNumber: string = ''): string {
        if (accountNumber.length !== 10) {
            return `XXX-XXX-XXX-X`;
        }
        return `XXX-${accountNumber[3]}-${accountNumber.slice(4, 8)}-X`;
    }

    function matchTypeAccount(type: string = ''): string {
        const typeToRoleMap: { [key: string]: string } = {
            'SVNG': 'บัญชีออมทรัพย์',
            'CURR': 'บัญชีกระแสรายวัน',
            'TD': 'บัญชีเงินฝากประจำ',
        };

        return typeToRoleMap[type] || 'เงินฝากออมทรัพย์';
    }

    const handleDelete = () => {
        if (!isHaveLastAccount) {
            const updatedBankAccounts = bankAccounts
                .filter(account => account.accountNumber !== account_no)
                .map(account => ({
                    accountNumber: account.accountNumber,
                    type: account.type,
                    isNotificationEnabled: account.isNotificationEnabled,
                }));
            dispatch(setBankAccounts(updatedBankAccounts));
            navigate(`/confirm-account`)
        }
    };

    return (
        <PageProvider>
            <div>
                <Title>บัญชีเงินฝาก</Title>
                <div style={{
                    marginTop: 16,
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    <img
                        src={account?.isFromKMA ? '/svg/passbook.svg' : '/svg/accBook.svg'}
                        alt="ks-passbook"
                        style={{
                            width: '40px',
                            height: '40px',
                        }}
                    />
                    <div style={{
                        flexGrow: 1,
                        paddingLeft: '10px',
                        paddingRight: '13px',
                        paddingBottom: '8px',
                        paddingTop: '8px',
                    }}>
                        <Description>{matchTypeAccount(account?.type)}</Description>
                        <Description>{maskAccountNumber(account?.accountNumber)}</Description>
                    </div>
                    <button
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            padding: '0 10px',
                        }}
                    >
                    </button>
                </div>
                <div style={{ height: 1, background: '#E8E8E8' }} />

                <div
                    onClick={handleDelete}
                    style={{
                        marginTop: 16,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        cursor: isHaveLastAccount ? 'not-allowed' : 'pointer',
                    }}>

                    <img
                        src={'/svg/bin.svg'}
                        alt="delete-account"
                        style={{
                            width: '16px',
                            height: '16px',
                            marginRight: 8,
                        }}
                    />

                    <Description
                        style={{ color: isHaveLastAccount ? Colors.SECONDARY_TEXT_COLOR : Colors.PRIMARY_TEXT_COLOR }}>
                        ลบออกจากบริการนี้
                    </Description>
                </div>
                {isHaveLastAccount &&
                    <Description style={{ color: Colors.SECONDARY_TEXT_COLOR }}>ไม่สามารถลบได้ เนื่องจากต้องมีผลิตภัณฑ์ที่ผูกกับ
                        บริการ Krungsri Simple อย่างน้อย 1 รายการ
                    </Description>
                }
                <div style={{
                    marginTop: 16,
                    marginBottom: isHaveLastAccount ? 321 : 387,
                    height: 1,
                    background: '#E8E8E8'
                }} />
            </div>
            <ButtomSpan>
                <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
            </ButtomSpan>
        </PageProvider>
    );
}
