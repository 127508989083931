import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ScreenAlert from '../../../components/common/ScreenAlert';
import PopupVerify from '../../../components/common/popup/PopupVerify';
import PopupAlert from '../../../components/common/popup/PopUpAlert';

const SuccessPage: React.FC = () => {

    return (
        <PageProvider>
            <ScreenAlert iconType={3}/>
        </PageProvider>
    );
};

export default SuccessPage;
