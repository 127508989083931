import React from 'react';
import { Description } from './Texts';

type CheckBoxProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
};

const CheckBox: React.FC<CheckBoxProps> = ({ checked, disabled = false, onChange, text }) => {
  return (
    <label
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <div
        style={{
          width: '21px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '8px',
        }}
      >
        {checked ? (
          <img
            src="/svg/checkbox.svg"
            alt="Checked"
            style={{ width: '21px', height: '21px' }}
          />
        ) : (
          <img
            src="/svg/uncheckbox.svg"
            alt="Unchecked"
            style={{ width: '21px', height: '21px', color: 'blue' }}
          />
        )}
      </div>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        style={{ display: 'none' }}
      />
      <Description>{text}</Description>
    </label>
  );
};

export default CheckBox;
