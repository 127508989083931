import React from "react";

interface ToggleSwitchProps {
  isToggled: boolean;
  onToggle: () => void;
}

export default function ToggleSwitch({ isToggled, onToggle }: ToggleSwitchProps) {
  return (
    <div
      style={{
        width: '48px',
        height: '28px',
        backgroundSize: 'cover',
        borderRadius: '12px',
        position: 'relative',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        backgroundColor: isToggled ? '#FFD400' : '#D7D7D7',
        WebkitTapHighlightColor: 'transparent',
      }}
      onClick={onToggle}
    >
      <div
        style={{
          position: 'absolute',
          top: '-2px',
          left: isToggled ? 'calc(100% - 35px)' : '-7px',
          transition: 'left 0.3s ease',
        }}
      >
        <img
          src={'/svg/notiSwitch.svg'}
          alt="switch"
        />
      </div>
    </div>
  );
}
