import React from 'react';
import Colors from '../../assets/styles/Colors';
import Fonts from '../../assets/styles/Fonts';

interface TitleProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const Title: React.FC<TitleProps> = ({ children, style }) => {
  return (
    <div
      style={{
        color: Colors.MAIN_TEXT_COLOR,
        fontFamily: Fonts.Krungsri_Bold,
        fontSize: '26px',
        lineHeight: '40px',
        // border: '1.5px solid #33abd0',
        ...style
      }}
    >
      {children}
    </div>
  );
};

export const SubTitle: React.FC<TitleProps> = ({ children, style }) => {
  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center',
      // border: '1.5px solid #33abd0',
      ...style

       }}>
      <div
        style={{
          backgroundColor: Colors.PRIMARY_KS_YELLOW,
          height: '24px',
          width: '4px',
        }}
      ></div>
      <div
        style={{
          fontFamily: Fonts.Krungsri_Bold,
          fontSize: '16px',
          lineHeight: '24px',
          marginLeft: '8px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Description: React.FC<TitleProps> = ({ children, style }) => {
  return (
    <div
      style={{
        // border: '1.5px solid #f5e2a8',
        color: Colors.PRIMARY_TEXT_COLOR,
        fontFamily: Fonts.Thongterm_Medium,
        fontSize: '16px',
        lineHeight: '29px',
        ...style
      }}
    >
      {children}
    </div>
  );
};
