import React from 'react';
import Colors from '../../../assets/styles/Colors';

interface PopupValidProps {
  isVisible?: boolean;
  title: string;
}

const PopupValid: React.FC<PopupValidProps> = ({
  isVisible = true,
  title,
}) => {
  if (!isVisible) return null;

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(89, 89, 89, 0.5)',
        zIndex: 1000,
    }}>
        <div style={{
            backgroundColor: '#fff',
            paddingTop: '40px',
            paddingBottom: '40px',
            width: '100%',
            textAlign: 'center',
        }}>
           <img src='/svg/valid.svg' 
           alt="Popup Icon" style={{
                width: '88px',
                height: '88px',
                marginBottom: '40px',
            }} />

            <div style={{
                color: Colors.MAIN_TEXT_COLOR,
                fontFamily: 'Krungsri_Bold',
                fontSize: '24px',
                lineHeight: '36px',
            }}>
                {title}
            </div>
        </div>
    </div>
);
};

export default PopupValid;
