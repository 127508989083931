import React, { useState, useEffect } from 'react';
import Colors from '../../assets/styles/Colors';
import { Description } from './Texts';
import Button from './Button';
import liff from '@line/liff'
import BottomSpan from '../layout/BottomSpan';

interface ScreenAlertProps {
    iconType?: number;
    countdownDuration?: number
    onCountdownEnd?: () => void;
}

const header: { [key: number]: string } = {
    1: 'ข้อมูลผู้สมัตร',
    2: 'ข้อมูลผู้สมัตร',
    3: '',
    4: 'ยืนยันด้วย OTP',
    5: '',
}

const iconSources: { [key: number]: string } = {
    1: '/svg/alert.svg',
    2: '/svg/suspend.svg',
    3: '/svg/valid.svg',
    4: '/svg/alert.svg',
    5: '/svg/valid.svg',
};

const title: { [key: number]: string } = {
    1: 'ไม่สามารถสมัครบริการได้ในขณะนี้',
    2: 'การสมัครบริการของคุณถูกระงับ',
    3: 'ขอบคุณสำหรับข้อมูล',
    4: 'ไม่สามารถส่งรหัส OTP ได้ในขณะนี้',
    5: 'ขอ Statement สำเร็จ'
};

const des: { [key: number]: string } = {
    1: `คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนดกรุณาสมัครบริการอีกครั้งในอีก`,
    2: `คุณกรอกข้อมูลไม่ถูกต้องเกินจำนวนครั้งที่กำหนด กรุณาติดต่อ Krungsri Call Center 1572 <br /> เพื่อยืนยันตัวตน ก่อนสมัครบริการอีกครั้ง`,
    3: ``,
    4: `เนื่องจากขอรหัส OTP เกินจำนวนครั้งที่กำหนดกรุณาลองอีกครั้งในอีก`,
    5: `ธนาคารได้รับคำขอของคุณเรียบร้อยแล้ว
    ระบบจะทำการจัดส่งรายการเดินบัญชี
    ให้คุณภายใน 1 ชั่วโมง`,
};


const buttonText: { [key: number]: string } = {
    1: 'กลับสู่หน้า LINE',
    2: 'โทร 1572',
    3: 'เริ่มใช้บริการ Krungsri Simple',
    4: 'กลับสู่หน้า LINE',
    5: 'กลับสู่หน้า LINE',
};

const ScreenAlert: React.FC<ScreenAlertProps> = ({
    iconType = 1,
    countdownDuration = 1800,
    onCountdownEnd,
}) => {

    const [timeLeft, setTimeLeft] = useState(countdownDuration);
    const iconSrc = iconSources[iconType] || iconSources[1];

    const closeLiffWindow = () => {
        liff.closeWindow();
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    if (onCountdownEnd) {
                        onCountdownEnd();
                    }
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const callPhoneNumber = () => {
        window.location.href = `tel:1572`;
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        }}>
            <div style={{
                width: '375px',
                margin: '0 0 46px 0'
            }}>
                {iconType === 2 || iconType === 1 || iconType === 4 ? (
                    <div style={{
                        fontSize: '26px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '40px'
                    }}>
                        {header[iconType]}
                    </div>
                ) : (
                    <div style={{
                        height: '40px'
                    }}>

                    </div>
                )}
            </div>
            <div style={{
                backgroundColor: '#fff',
                paddingTop: '40px',
                paddingBottom: '40px',
                width: '100%',
                textAlign: 'center',

            }}>
                {iconSrc && <img src={iconSrc} alt="Popup Icon" style={{
                    width: '88px',
                    height: '88px',
                    marginBottom: '40px',
                }} />}
                <div style={{
                    color: Colors.MAIN_TEXT_COLOR,
                    fontFamily: 'Krungsri_Bold',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    lineHeight: '36px',

                }}>
                    {title[iconType]}
                </div>
                <div style={{
                    marginTop: 8,
                    width: 343,
                    textAlign: 'center',
                    margin: '0 auto',
                    whiteSpace: 'pre-line',
                }}>

                    <span style={{ fontWeight: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <Description>
                            {des[iconType]}
                            {(iconType === 1 || iconType === 4) && (
                                <>
                                    <span style={{
                                        color: Colors.SECONDARY_BROWN,
                                        fontFamily: 'Thongterm',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '29px',
                                        marginLeft: '4px',
                                    }}>
                                        {formatTime(timeLeft)}
                                    </span>
                                    <span style={{
                                        color: Colors.PRIMARY_TEXT_COLOR,
                                        fontFamily: 'Thongterm',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '29px',
                                        marginLeft: '4px',
                                    }}>
                                        นาที
                                    </span>
                                </>
                            )}
                        </Description>
                    </span>
                </div>
            </div>

            <BottomSpan>
                {iconType === 2 ?
                    <div>
                        <Button text={buttonText[iconType]} onClick={callPhoneNumber} type={0} />
                        <div style={{ marginBottom: 24 }} />
                        <Button text={buttonText[1]} onClick={closeLiffWindow} type={2} />
                    </div>
                    : (
                        iconType === 1 ?
                            <Button text={buttonText[iconType]} onClick={closeLiffWindow} type={0} />
                            : <Button text={buttonText[iconType]} onClick={closeLiffWindow} type={0} />
                    )
                }
            </BottomSpan>
        </div>
    );
};

export default ScreenAlert;
