import React, { useState, ChangeEvent, useEffect } from "react";
import PageProvider from '../../../components/layout/PageProvider';
import ScreenAlert from '../../../components/common/ScreenAlert';
import Colors from '../../../assets/styles/Colors';
import ButtonSpan from "../../../components/layout/BottomSpan";
import Button from '../../../components/common/Button';
import { Title, Description } from '../../../components/common/Texts';
import StatementInfo from "../../../components/common/StatementInfo";
import CheckButton from "../../../components/common/CustomRadio";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateStatement, getAccountDetail } from "../../../actions/statementAction";
import { RootState } from "../../../reducers/rootReducer";
import { accountSelector } from "../../../reducers/accountReducer";

const baseInputStyle: React.CSSProperties = {
    boxSizing: 'border-box',
    outline: 'none',
    width: '100%',
    height: '44px',
    flexShrink: 0,
    borderRadius: '3px',
    border: `1px solid ${Colors.STROKE_NORMAL}`,
    background: Colors.WHITE,
    color: '#949494',
    fontFamily: 'Thongterm',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '29px',
    paddingLeft: '16px',
};

const inputTypeStyle: React.CSSProperties = {
    fontWeight: '700',
    color: '#222222',
};

const inputErrorStyle: React.CSSProperties = {
    borderColor: '#D00000',
    borderRadius: '3px',
};

const errorTextStyle: React.CSSProperties = {
    color: '#D00000',
};

export default function RequestStatementPage() {

    const dispatch = useDispatch()
    
    const { accountData } = useSelector((state: RootState) => accountSelector(state));
    const accountNumber = accountData.accountNumber || 'XXX-X-XXXXX-X';
    const accountType = accountData.accountType || '';
    const [email, setEmail] = useState<string>('');
    const [isEmailFocus, setIsEmailFocus] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
    const [language, setLanguage] = useState<string>("TH");
    const [showAlert, setShowAlert] = useState<boolean>(false);

    useEffect(() => {
            dispatch(getAccountDetail());
    }, [dispatch]);

    useEffect(() => {
        setEmail(accountData?.email || '');
        if (validateEmail(email)) {
            setIsEmailValid(true)
        }
    }, [accountData?.email]);

    useEffect(() => {
        if (validateEmail(email)) {
            setIsEmailValid(true)
        }
    }, [email]);

    const navigate = useNavigate();

    function matchTypeAccount(type: string = ''): string {
        const typeToRoleMap: { [key: string]: string } = {
            'SVNG': 'บัญชีออมทรัพย์',
            'CURR': 'บัญชีกระแสรายวัน',
            'TD': 'บัญชีเงินฝากประจำ',
        };

        return typeToRoleMap[type] || 'บัญชีออมทรัพย์';
    }

    const statementPeriod = [
        { label: '1 เดือนล่าสุด', value: 30 },
        { label: '3 เดือนล่าสุด', value: 90 },
        { label: '6 เดือนล่าสุด', value: 180 },
        { label: '9 เดือนล่าสุด', value: 270 },
        { label: '12 เดือนล่าสุด', value: 360 }
    ];

    const timezoneOffsetHours = 7

    const validateEmail = (email: string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((([a-zA-Z\-0-9]{2,})+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const handleEmailFocus = () => {
        setIsEmailFocus(true);
    };

    const handleEmailBlur = () => {
        setIsEmailFocus(false);
        if (!email) {
            setEmailError("กรุณากรอกอีเมล");
        }
    };

    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        if (value.length === 0) {
            setEmailError("กรุณากรอกอีเมล");
            setIsEmailValid(false);
        } else if (!validateEmail(value)) {
            setEmailError("กรุณากรอกอีเมลให้ถูกต้อง เช่น simple@krungsri.com");
            setIsEmailValid(false);
        } else {
            setEmailError("");
            setIsEmailValid(true);
        }
    };

    function calculateDaysAgoInTimezone(inputDate: string, daysAgo: number, timezoneOffsetHours: number): string {
        const date = new Date(inputDate);
        date.setDate(date.getDate() - daysAgo);
        const timezoneOffsetMs = timezoneOffsetHours * 60 * 60 * 1000;
        const localDate = new Date(date.getTime() + timezoneOffsetMs);
        const isoString = localDate.toISOString();
        const timezoneOffset = `+${String(timezoneOffsetHours).padStart(2, '0')}:00`;
        return isoString.replace('Z', timezoneOffset).replace(/\.\d{3}/, '');
    }

    function formatDateWithTimezone(inputDate: string, daysAgo: number, timezoneOffsetHours: number): { [key: string]: string } {
        const fromDate = calculateDaysAgoInTimezone(inputDate, daysAgo, timezoneOffsetHours);
        const inputDateObj = new Date(inputDate);
        inputDateObj.setHours(23, 59, 59, 0);
        const timezoneOffsetMs = timezoneOffsetHours * 60 * 60 * 1000;
        const toDate = new Date(inputDateObj.getTime() + timezoneOffsetMs);
        const toIsoString = toDate.toISOString();
        const timezoneOffset = `+${String(timezoneOffsetHours).padStart(2, '0')}:00`;
        return {
            fromBookingDateTime: fromDate,
            toBookingDateTime: toIsoString.replace('Z', timezoneOffset).replace(/\.\d{3}/, '')
        };
    }

    const handleSubmit = () => {
        const now = new Date();
        const inputDate = now.toISOString().split('T')[0] + 'T00:00:00';
        const { fromBookingDateTime, toBookingDateTime } = formatDateWithTimezone(inputDate, selectedPeriod, timezoneOffsetHours);
        dispatch(generateStatement({ language, fromBookingDateTime, toBookingDateTime, email }))
    };

    const getInputStyle = (hasError: boolean, isFocused: boolean, isType: boolean): React.CSSProperties => ({
        ...baseInputStyle,
        ...(hasError && !isFocused ? inputErrorStyle : {}),
        ...(isType ? inputTypeStyle : {}),
    });

    return (
        <PageProvider>
            {showAlert ? (
                <div>
                    <ScreenAlert
                        iconType={5}
                        onCountdownEnd={() => setShowAlert(false)}
                        countdownDuration={10}
                    />
                </div>
            ) : (
                <div>
                    <Title>{matchTypeAccount(accountType)} <br />{accountNumber}</Title>
                    <div style={{ marginTop: 8 }}>
                        <div style={{ marginBottom: 16 }}>
                            <Description>เลือกขอ Statement ย้อนหลังได้สูงสุด 12 เดือน</Description>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                    }}>
                        <div>
                            <Description>เลือกช่วงเวลา</Description>
                            {statementPeriod.map((val, idx) => (
                                <StatementInfo
                                    key={idx}
                                    text={val.label}
                                    checked={selectedPeriod === val.value}
                                    size={16}
                                    onChange={() => setSelectedPeriod(val.value)}
                                />
                            ))}
                        </div>
                        <div>
                            <Description>เลือกภาษาของ Statement</Description>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '31px',
                                marginTop: '10px',
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckButton
                                        checked={language === 'TH'}
                                        size={24}
                                        onChange={() => setLanguage('TH')}
                                    />
                                    <Description>ไทย</Description>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckButton
                                        checked={language === 'EN'}
                                        size={24}
                                        onChange={() => setLanguage('EN')}
                                    />
                                    <Description>อังกฤษ</Description>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Description>ส่งไปที่อีเมล</Description>
                            <input
                                type="text"
                                placeholder="ตัวอย่าง: simple@krungsri.com"
                                value={email}
                                onChange={handleEmail}
                                onFocus={handleEmailFocus}
                                onBlur={handleEmailBlur}
                                style={getInputStyle(!!emailError, isEmailFocus, !!email)}
                            />
                            {!isEmailFocus && emailError && <div style={errorTextStyle}>{emailError}</div>}
                        </div>
                    </div>
                    <ButtonSpan>
                        <Button
                            text={'ส่งคำขอ'}
                            type={!isEmailValid || !selectedPeriod ? 1 : 0}
                            onClick={handleSubmit}
                        />
                    </ButtonSpan>
                </div>
            )}
        </PageProvider>
    );
}
