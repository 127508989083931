import React, { ChangeEvent, useState } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title, SubTitle, Description } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../assets/styles/Colors';
import { useDispatch } from 'react-redux';
import { generateOtp } from '../../../actions/registerAction';
import TextField from '../../../components/common/InputField';
import CustomInput from '../../../components/common/CustomInput';
import { formatMobileNumber } from '../../../utils/formatInput';

const inputTypeStyle: React.CSSProperties = {
    width: '343px',
    flexShrink: 0,
    borderRadius: '3px',
    border: `1px solid #E8E8E8`,
    backgroundColor: '#F0EFEE',
    fontFamily: 'Thongterm_Bold',
    color: '#222222',
    lineHeight: '29px',
    padding: '8px 0 7px 16px',
    fontSize: '16px',
};

const ReceiveOTPPage: React.FC = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const formatPhoneNumber = (value: string): string => {
        const numericValue = value.replace(/\D/g, "");
        let formattedValue = numericValue;
        if (numericValue.length > 3) {
            formattedValue = numericValue.slice(0, 3) + "-" + numericValue.slice(3);
        }
        if (numericValue.length > 6) {
            formattedValue = formattedValue.slice(0, 7) + "-" + formattedValue.slice(7);
        }
        return formattedValue.slice(0, 12);
    };
    const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const formattedValue = formatPhoneNumber(value);
        setPhoneNumber(formattedValue);
        if (formattedValue.length === 0) {
            setIsPhoneValid(false);
        } else if (formattedValue.length > 0 && formattedValue.length < 12) {
            setIsPhoneValid(false);
        } else {
            setIsPhoneValid(true);
        }
    };

    const goToNextPage = () => {
        dispatch(generateOtp({ flow: "statement", mobileNumber: phoneNumber.replace(/-/g, ''), isResend: false }))
    };

    const goToPreviousPage = () => {
        navigate(-1);
    };

    const handleMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value)
    };

    const validateMobileNumber = (value: string) => {
        if (!value) return 'กรุณากรอกเบอร์มือถือ';
        if (value.replace(/\D/g, '').length !== 10) return 'กรุณากรอกเบอร์มือถือ 10 หลักให้ถูกต้อง';
        return null;
      };
    
      const checkIsCanGoNext = () => {
        const mobileNumberError = validateMobileNumber(phoneNumber);
        setIsPhoneValid(!mobileNumberError)
    };

    return (
        <PageProvider>
            <Title style={{ marginBottom: 8 }}>ตรวจสอบช่องทางรับ OTP</Title>
            <CustomInput
                title='เบอร์มือถือ'
                placeholder='ตัวอย่าง: 123-4-51234-1'
                // subtitle='หมายเหตุ: หากเบอร์มือถือไม่ถูกต้องหรือต้องการเปลี่ยนแปลงข้อมูล กรุณาติดต่อ Krungsri Call Center 1572'
                value={phoneNumber}
                onChange={handleMobileNo}
                type='tel'
                validate={validateMobileNumber}
                format={formatMobileNumber}
                onBlur={checkIsCanGoNext}
                validateOnInit={false}
            />
            <ButtomSpan>
                <Button text={'ถัดไป'} type={!isPhoneValid ? 1 : 0} onClick={goToNextPage} />
                <div style={{ marginBottom: 24 }} />
                <Button text={'ย้อนกลับ'} type={2} onClick={goToPreviousPage} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default ReceiveOTPPage;
