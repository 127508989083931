import { call, put, take, takeLatest } from 'redux-saga/effects';
import { ADD_PRODUCT, GET_SURVEYS_LIST, setSurveysList, SAVE_SURVEYS_LIST } from "../actions/accountAction";
import { setBankAccounts } from "../actions/registerAction";
import { apiAddAccount, apiGetSurveysList, apiSaveSurveysList } from "../api/api";
import { showSpinner, hideSpinner } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { navigationRef } from '../utils/navigation';

function* workerAddProduct(payload: any): Generator<any, void, any> {
    const { accountNumber, oldBankList } = payload;
    try {
        yield call(showSpinner);
        const response = yield call(apiAddAccount, accountNumber);
        if (response.success) {
            const updatedBankList = [
                ...oldBankList,
                {
                    accountNumber: response.data.dispalyAccountNo,
                    type: response.data.displayProductType,
                    isNotificationEnabled: true,
                    isFromKMA: false,
                },
            ];
            yield put(setBankAccounts(updatedBankList));
            if (navigationRef.current) {
                navigationRef.current('/confirm-account');
            } else {
                yield call(handleStatusError, '404', 'navigationRef.current is not set');
            }
        } else {
            yield call(handleStatusError, response.statusCode, response.message);
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherAddProduct(): Generator<any, void, any> {
    while (true) {
        const action = yield take(ADD_PRODUCT);
        yield call(workerAddProduct, action.payload);
    }
}

function* workerGetSurveysList(): Generator<any, void, any> {
    try {
        yield call(showSpinner);
        const response = yield call(apiGetSurveysList);
        if (response.success) {
            const mappedChoices = response.data.choices.map((choice: string, index: number) => ({
                id: (index + 1).toString(),
                name: choice,
                type: 2,
            }));
            yield put(setSurveysList(mappedChoices))
        } else {
            yield call(handleStatusError, response.statusCode, response.message);
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGetSurveysList(): Generator<any, void, any> {
    yield takeLatest(GET_SURVEYS_LIST, workerGetSurveysList);
}

function* workerSaveurveysList(payload: any): Generator<any, void, any> {
    try {
        yield call(showSpinner);
        const response = yield call(apiSaveSurveysList, payload.answers);
        if (response.success) {
            if (navigationRef.current) {
                navigationRef.current('/success');
            } else {
                yield call(handleStatusError, '404', 'navigationRef.current is not set');
            }
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherSaveurveysList(): Generator<any, void, any> {
    while (true) {
        const action = yield take(SAVE_SURVEYS_LIST);
        yield call(workerSaveurveysList, action.payload);
    }
}