import React from 'react';
import Colors from '../../assets/styles/Colors';

interface SpinnerProps {
    isVisible?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ isVisible = true }) => {
    if (!isVisible) return null;

    const loaderWrapperStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(89, 89, 89, 0.5)',
        zIndex: 1000,
    };

    const dotStyle: React.CSSProperties = {
        width: '4px', // Initial size of each dot
        height: '4px', // Initial size of each dot
        borderRadius: '50%',
        backgroundColor: Colors.PRIMARY_KS_YELLOW, // Blue color
        margin: '0 8px', // Space between dots
        animation: 'dotPulse 1.2s infinite ease-in-out',
    };

    const dot1Style: React.CSSProperties = {
        ...dotStyle,
        animationDelay: '0s',
    };

    const dot2Style: React.CSSProperties = {
        ...dotStyle,
        animationDelay: '0.2s',
    };

    const dot3Style: React.CSSProperties = {
        ...dotStyle,
        animationDelay: '0.4s',
    };

    const keyframesStyle = `
        @keyframes dotPulse {
            0%, 100% {
                transform: scale(1); /* Scale from 4px */
            }
            50% {
                transform: scale(2); /* Scale to 8px */
            }
            75% {
                transform: scale(4); /* Scale to 16px */
            }
        }
    `;

    return (
        <div style={loaderWrapperStyle}>
            <style>{keyframesStyle}</style>
            <div style={dot1Style}></div>
            <div style={dot2Style}></div>
            <div style={dot3Style}></div>
        </div>
    );
};

export default Spinner;
