import React from 'react';
import Colors from '../../assets/styles/Colors';

type ButtonType = 0 | 1 | 2 | 3;

interface ButtonProps {
  onClick: () => void;
  text: any;
  type: ButtonType;
}

const backgroundType: { [key in ButtonType]: string } = {
  0: Colors.PRIMARY_KS_YELLOW,
  1: Colors.BACKGROUND_DISABLE,
  2: Colors.WHITE,
  3: Colors.WHITE,
};

const borderType: { [key in ButtonType]: string } = {
  0: `${Colors.WHITE}`,
  1: `${Colors.WHITE}`,
  2: `1px solid ${Colors.STROKE_NORMAL}`,
  3: `1px solid ${Colors.ORANGE}`,
};

const colorType: { [key in ButtonType]: string } = {
  0: Colors.SECONDARY_BROWN,
  1: Colors.SECONDARY_TEXT_COLOR,
  2: Colors.SECONDARY_BROWN,
  3: Colors.ORANGE
};


const Button: React.FC<ButtonProps> = ({ onClick, text, type }) => {
  return (
    <button
      disabled={type === 1}
      onClick={onClick}
      style={{
        alignItems: 'center',
        background: backgroundType[type],
        border: borderType[type],
        borderRadius: '3px',
        color: colorType[type],
        display: 'flex',
        flexShrink: 0,
        fontFamily: 'Krungsri_Bold',
        fontSize: '16px',
        fontStyle: 'normal',
        height: '44px',
        justifyContent: 'center',
        lineHeight: '30px',
        textAlign: 'center',
        maxWidth: '270px',
        width: '72vw',
      }}>
      {text}
    </button>
  );
};

export default Button;
