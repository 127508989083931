import React from 'react';

const ReCAPTCHAMessage: React.FC = () => {
  return (
    <div
      style={{
        paddingTop: '16px',
        textAlign: 'center',
        fontSize: '12px',
        fontStyle: 'normal',
        color: '#949494',
        fontFamily: 'Thongterm'
      }}
    >
      This site is protected by reCAPTCHA and the Google
      <div>
        <a
          href="https://policies.google.com/privacy"
          style={{
            color: '#F76F00',
            textDecoration: 'none'
          }}
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://policies.google.com/terms"
          style={{
            color: '#F76F00',
            textDecoration: 'none'
          }}
          onMouseEnter={e => e.currentTarget.style.textDecoration = 'underline'}
          onMouseLeave={e => e.currentTarget.style.textDecoration = 'none'}
        >
          Terms of Service
        </a>{" "}
        apply
      </div>
    </div>
  );
};

export default ReCAPTCHAMessage;
