import {
  SET_TERMS_AND_CONDITIONS,
  SET_REGISTER_REFERENCE,
  SAVE_SENSITIVE_DATA,
  SET_AUTHENTICATION_REFERENCE,
  SET_BANK_ACCOUNTS,
  SET_OTP_REFERENCE,
} from '../actions/registerAction';
import {
  SET_SURVEYS_LIST,
} from '../actions/accountAction'
import { RootState } from '../store/store';
import { createSelector } from 'reselect';

interface BankAccount {
  accountNumber: string;
  type: string;
  isNotificationEnabled: boolean;
  isFromKMA: boolean
}

export interface TermsAndConditionsState {
  terms: string;
  id: string;
  idCard: string;
  mobileNumber: string;
  referral: string;
  registerRef: string;
  otpRef: string;
  mustConfirmRegisterForReplace: boolean;
  authRef: string,
  authenticationWebUri: string,
  authenticationApplicationUri: string,
  expiryDateTime: string,
  bankAccounts: BankAccount[];
  survey: { id: string; name: string; type: number }[];
}

export interface TermsAndConditionsAction {
  type: string;
  payload: {
    terms: string;
    id: string;
    idCard: string;
    mobileNumber: string;
    referral: string;
    registerRef: string;
    otpRef: string;
    mustConfirmRegisterForReplace: boolean;
    authRef: string,
    authenticationWebUri: string,
    authenticationApplicationUri: string,
    expiryDateTime: string,
    bankAccounts: BankAccount[];
    survey: [],
  };
}

const initialState: TermsAndConditionsState = {
  terms: '',
  id: '',
  idCard: '',
  mobileNumber: '',
  otpRef: '',
  referral: '',
  registerRef: '',
  mustConfirmRegisterForReplace: false,
  authRef: '',
  authenticationWebUri: '',
  authenticationApplicationUri: '',
  expiryDateTime: '',
  bankAccounts: [],
  survey: [],
};


export const registerReducer = (
  state: TermsAndConditionsState = initialState,
  action: TermsAndConditionsAction
): TermsAndConditionsState => {
  switch (action.type) {
    case SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        terms: action.payload.terms,
        id: action.payload.id,
      };
    case SAVE_SENSITIVE_DATA:
      return {
        ...state,
        idCard: action.payload.idCard,
        mobileNumber: action.payload.mobileNumber,
        referral: action.payload.referral,
      };
    case SET_REGISTER_REFERENCE:
      return {
        ...state,
        registerRef: action.payload.registerRef,
        mustConfirmRegisterForReplace: action.payload.mustConfirmRegisterForReplace,
      };
    case SET_OTP_REFERENCE:
      return {
        ...state,
        otpRef: action.payload.otpRef,
      };
    case SET_AUTHENTICATION_REFERENCE:
      return {
        ...state,
        authRef: action.payload.authRef,
        authenticationWebUri: action.payload.authenticationWebUri,
        authenticationApplicationUri: action.payload.authenticationApplicationUri,
        expiryDateTime: action.payload.expiryDateTime,
      };
    case SET_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: action.payload.bankAccounts,
      };
    case SET_SURVEYS_LIST:
      return {
        ...state,
        survey: action.payload.survey,
      };
    default:
      return state;
  }
};

const selectRegister = (state: RootState) => state.register;

export const registerSelector = createSelector(
  [selectRegister],
  (register) => ({
    terms: register?.terms ?? '',
    id: register?.id ?? '',
    idCard: register?.idCard ?? '',
    mobileNumber: register?.mobileNumber ?? '',
    referral: register?.referral ?? '',
    otpRef: register?.otpRef ?? '',
    registerRef: register?.registerRef ?? '',
    mustConfirmRegisterForReplace: register?.mustConfirmRegisterForReplace ?? false,
    authRef: register?.authRef ?? '',
    authenticationWebUri: register?.authenticationWebUri ?? '',
    authenticationApplicationUri: register?.authenticationApplicationUri ?? '',
    expiryDateTime: register?.expiryDateTime ?? '',
    bankAccounts: register?.bankAccounts ?? [],
    surveys: register?.survey ?? [],
  })
);