import React from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import ButtomSpan from "../../../components/layout/BottomSpan"
import Button from '../../../components/common/Button';
import { Title } from '../../../components/common/Texts';
import { useDispatch } from 'react-redux';
import { exchangeLineToken } from '../../../actions/tokenAction';
import useLineIdToken from '../../../hooks/useLineIdToken';
import RenderCards from '../components/RenderCards';

const KrungsriSimpleSerivePage: React.FC = () => {

    const lineIdToken = useLineIdToken();
    const dispatch = useDispatch();

    const handleCopyToClipboard = () => {
        if (lineIdToken) {
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(lineIdToken).then(() => {
                    alert(`Line Token is ${lineIdToken}`);
                }).catch(err => {
                    alert('Failed to copy!');
                });
            } else {
                const textArea = document.createElement('textarea');
                textArea.value = lineIdToken;
                textArea.style.position = 'fixed';
                textArea.style.left = '-9999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    document.execCommand('copy');
                    alert(`Line Token is ${lineIdToken}`);
                } catch (err) {
                    alert('Failed to copy!');
                    console.error(err);
                }

                document.body.removeChild(textArea);
            }
        } else {
            alert('Can not fine line token pls try again');
        }
    };

    const alertAppVerison = () => {
        alert(`App Version: 0.0.12`);
    };

    const alertLiffId = () => {
        const liffId = process.env.REACT_APP_LIFF_ID;
        alert(`Liff ID : ${liffId}`);
    };

    const goToNextPage = async () => {
        dispatch(exchangeLineToken(lineIdToken, '/terms-and-conditions'));
    };

    const items = [
        {
            subtitle: 'แจ้งเตือน',
            description: 'รู้ทุกความเคลื่อนไหว เมื่อเงินเข้า-ออกฟรี! สูงสุด 15 บัญชี',
            image: 'account',
            onClickHandler: alertAppVerison,
        },
        {
            subtitle: 'เช็กยอด',
            description: 'ตรวจสอบยอดเงินคงเหลือในบัญชีได้ทุกที่ทุกเวลา',
            image: 'bookbank',
            onClickHandler: handleCopyToClipboard,
        },
        {
            subtitle: 'ขอ Statement',
            description: 'ขอดูรายการเดินบัญชีย้อนหลังได้ด้วยตนเองทันที',
            image: 'statement',
            onClickHandler: alertLiffId,
        },
    ];

    return (
        <PageProvider>
            <Title style={{ marginBottom: 16 }}>บริการ Krungsri Simple</Title>
            <RenderCards items={items} />
            <ButtomSpan>
                <Button text={'สมัครบริการ'} onClick={goToNextPage} type={0} />
            </ButtomSpan>
        </PageProvider>
    );
};

export default KrungsriSimpleSerivePage;
