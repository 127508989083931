import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

const handleApiError = (error: any) => {
  if (error.response) {
    // Server responded with a status code out of the 2xx range
    return {
      success: false,
      message: `ERROR MESSAGE: ${error.response.data?.error.message}`,
      statusCode: `ERROR CODE: ${error.response.data?.error.code}`,
    };
  } else if (error.request) {
    // No response received from the server
    return {
      success: false,
      message: 'No response received from server',
    };
  } else {
    // Error in setting up the request
    return {
      success: false,
      message: error.message || 'Error in sending request',
    };
  }
};

export const apiGetVerify = async (lineIdToken: string) => {
  try {
    const response = await api.post('/services/lks/inter/api/v1/verify-token', {
      "lineIdToken": lineIdToken,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGetTermsAndConditions = async () => {
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.get('/services/lks/inter/api/v1/term-and-conditions', {
      maxBodyLength: Infinity,
      headers: {
        'Authorization': `Bearer ${lksToken}`
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiAcceptTermsAndConditions = async (id: string) => {
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.post('/services/lks/inter/api/v1/term-and-conditions/accept',
      {
        acceptedId: id
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiSaveUserInfo = async (identificationNumber: string, mobileNumber: string, referral: string, deviceOS: string) => {
  const lksToken = sessionStorage.getItem('lksToken');
  const isHaveKMA = JSON.parse(sessionStorage.getItem('isHaveKMA') || 'false')

  let endpoint = '/services/lks/inter/api/v1/registers/otp/pre-register';
  if (isHaveKMA) {
    endpoint = '/services/lks/inter/api/v1/registers/kma/pre-register';
  }
  try {
    const response = await api.post(endpoint,
      {
        "identificationNumber": identificationNumber,
        "mobileNumber": mobileNumber,
        "referral": referral,
        "recaptchaToken": "",
        "deviceOS": deviceOS
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGenerateOtp = async () => {
  const lksToken = sessionStorage.getItem('lksToken');
  const registerRef = sessionStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/otp/request',
      {
        "registerRef": registerRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiVerifyOtp = async (otpRef: string, otpCode: string) => {
  const lksToken = sessionStorage.getItem('lksToken');
  const registerRef = sessionStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/otp/verify',
      {
        "otpRef": otpRef,
        "otpCode": otpCode,
        "registerRef": registerRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};


export const apiCreateDeepLink = async () => {
  const lksToken = sessionStorage.getItem('lksToken');
  const registerRef = sessionStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/kma/deep-link/request',
      {
        "registerRef": registerRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiVerifyDeepLink = async (authRef: string) => {
  const lksToken = sessionStorage.getItem('lksToken');
  const registerRef = sessionStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/kma/deep-link/verify',
      {
        "registerRef": registerRef,
        "authRef": authRef
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiSubmitRegister = async (registerAccounts: []) => {
  const lksToken = sessionStorage.getItem('lksToken');
  const registerRef = sessionStorage.getItem('registerRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/registers/submit',
      {
        "registerRef": registerRef,
        "registerAccounts": registerAccounts
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiAddAccount = async (accountNumber: string) => {
  const registerRef = sessionStorage.getItem('registerRef');
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/soft-add',
      {
        "registerRef": registerRef,
        "accountNumber": accountNumber
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGetSurveysList = async () => {
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.get('/services/lks/inter/api/v1/active/surveys',
      {
        headers: {
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiSaveSurveysList = async (answers: string) => {
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.put('/services/lks/inter/api/v1/survey-submissions/6a7eacbd-72ee-494b-8658-c41689fe813a',
      {
        "answers": answers
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
}

export const apiGetAccountDetail = async () => {
  const lksToken = sessionStorage.getItem('lksToken');
  const accountRef = sessionStorage.getItem('accountRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/bank-accounts/account-details/statement',
      {
        "accountRef": accountRef,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGenerateStatement = async (
  language: string,
  fromBookingDateTime: string,
  toBookingDateTime: string,
  email: string,
) => {
  const lksToken = sessionStorage.getItem('lksToken');
  const accountRef = sessionStorage.getItem('accountRef');
  try {
    const response = await api.post('/services/lks/inter/api/v1/statements',
      {
        "accountRef": accountRef,
        "Language": language,
        "FromBookingDateTime": fromBookingDateTime,
        "ToBookingDateTime": toBookingDateTime,
        "Email": email,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiGenerateOtpForStatement = async (
  mobileNumber: string,
) => {
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/otp/request',
      {
        "mobileNumber": mobileNumber,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiVerifyOtpForStatement = async (otpRef: string, otpCode: string) => {
  const lksToken = sessionStorage.getItem('lksToken');
  try {
    const response = await api.post('/services/lks/inter/api/v1/verification/otp/verify',
      {
        "otpRef": otpRef,
        "otpCode": otpCode,
      },
      {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${lksToken}`
        }
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    return handleApiError(error);
  }
};