import React, { ReactNode } from 'react';
import Colors from '../../assets/styles/Colors';

interface BottomSpanProps {
  children: ReactNode;
}

const BottomSpan: React.FC<BottomSpanProps> = ({ children }) => {
  return (
    <footer style={{
      alignItems: 'center',
      backgroundColor: Colors.WHITE,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      paddingBottom:'16px',
      // border: '1.5px solid #fdfc00',
      position: 'fixed',
    }}>
      {children}
    </footer>
  );
};

export default BottomSpan;
