import React, { useState, useEffect, useMemo } from 'react';
import PageProvider from '../../../components/layout/PageProvider';
import AccountInfo from '../../../components/common/AccountInfo';
import Button from '../../../components/common/Button';
import ToggleDropdown from '../../../components/common/ToggleDropDown';
import { Title } from '../../../components/common/Texts';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerSelector } from '../../../reducers/registerReducer';
import { RootState } from '../../../reducers/rootReducer';
import { submitRegister } from '../../../actions/registerAction';

const ConfirmAccountPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { bankAccounts } = useSelector((state: RootState) => registerSelector(state));
    const [buttonType, setButtonType] = useState<number>(2);

    const [accounts, setAccounts] = useState(bankAccounts);
    const memoizedAccountInfo = useMemo(() => bankAccounts, []);
    useEffect(() => {
        if (memoizedAccountInfo.length > 11) {
            setButtonType(1);
        } else {
            setButtonType(2);
        }
    }, [memoizedAccountInfo]);

    const handleToggle = (accountNumber: string) => {
        setAccounts((prevAccounts) =>
            prevAccounts.map((account) =>
                account.accountNumber === accountNumber
                    ? { ...account, isNotificationEnabled: !account.isNotificationEnabled } // Toggle isFromUSER
                    : account
            )
        );
    };

    const handleAddProduct = () => {
        if (bankAccounts.length <= 15) {
            navigate('/add-product')
        }
    }
    const goToNextPage = () => {
        const bankAccountsWithOutFlagKMA = accounts.map(({ isFromKMA, ...rest }) => rest);
        dispatch(submitRegister(bankAccountsWithOutFlagKMA))
    };

    return (
        <PageProvider>
            <div>
                <Title>ยืนยันผลิตภัณฑ์ที่รับบริการ</Title>
                <div style={{ marginTop: 8 }} />
                <ToggleDropdown shortText={"เพื่อความปลอดภัยสูงสุด กรุณาหลีกเลี่ยงการ"} longText={"เพื่อความปลอดภัยสูงสุด กรุณาหลีกเลี่ยงการใช้งานผ่านอุปกรณ์สาธารณะ ทั้งนี้ ระบบจะไม่สามารถหักเงินจากบัญชีธนาคารผ่านบริการนี้ได้"} />
                <div style={{ marginTop: 8, marginBottom: 16, height: 1, background: '#E8E8E8' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Title>บัญชีเงินฝาก</Title>
                    <Title style={{ marginLeft: 'auto', fontSize:'14px' }}>เปิด-ปิดแจ้งเตือน</Title>
                </div>
                <div>
                    {accounts.slice(0, 15).map((account) => (
                        <AccountInfo
                            key={account.accountNumber}
                            accountNo={account.accountNumber}
                            type={account.type}
                            isNotificationEnabled={account.isNotificationEnabled}
                            status="Enable"
                            isKMA={false}
                            onToggle={() => handleToggle(account.accountNumber)}
                        />
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
                    <Button
                        text={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={'/svg/plus.svg'}
                                    alt="add product"
                                />
                                <span style={{ marginLeft: '8px' }}>เพิ่มบัญชี</span>
                            </span>
                        }
                        type={buttonType === 1 ? 1 : 2}
                        onClick={handleAddProduct}
                    />
                </div>
            </div>
            <div style={{ paddingTop: '120px' }} />
            <div
                style={{
                    position: 'fixed',
                    left: '-1px',
                    bottom: '0',
                    width: '100%',
                    height: '108px',
                    background: '#FFFFFF',
                    boxShadow: '1px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button text={'ยืนยัน'} onClick={goToNextPage} type={0} />
            </div>
        </PageProvider>
    );
};

export default ConfirmAccountPage;
