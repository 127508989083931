import CustomRadio from './CustomRadio';
import Colors from '../../assets/styles/Colors';

interface StatementInfoProps {
  text: string;
  size: number;
  checked: boolean;
  onChange: () => void;
}

export default function StatementInfo({ text, size, checked, onChange }: StatementInfoProps) {
  return (
    <div style={
      {
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderBottom: '1px solid #E8E8E8',
        padding: '16px 0',
      }
    }>
      <span style={{
        fontFamily: 'Krungsri Condensed',
        fontSize: '16px',
        color: Colors.PRIMARY_TEXT_COLOR,
      }}>
        {text}
      </span>
      <CustomRadio
        size={size}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};
