import React, { useState } from 'react';
import { Description } from '../common/Texts';

type ToggleDropdownProps = {
    shortText: string;
    longText: string;
};

const ToggleDropdown: React.FC<ToggleDropdownProps> = ({ shortText, longText }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ display: 'flex', cursor: 'pointer', userSelect: 'none', justifyContent: 'space-between' }} onClick={handleToggle}>
            {isOpen ? (
                <Description
                    style={{
                        // whiteSpace: 'nowrap',
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                    }}
                >
                    {longText}
                </Description>
            ) : (
                <Description
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {longText}
                </Description>
            )}
            <div style={{ paddingLeft: '16px', paddingTop: '3px' }}>
                <img
                    src={'/svg/chevronRight.svg'}
                    alt="toggle"
                    style={{
                        width: '24px',
                        height: '24px',
                        transition: 'transform 0.3s ease',
                        transform: isOpen ? 'rotate(270deg)' : 'rotate(90deg)',
                    }}
                />
            </div>
        </div>
    );
};

export default ToggleDropdown;
