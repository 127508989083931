import { all, call } from 'redux-saga/effects';
import { watcherExchangeLineToken } from './tokenSaga';
import {
  watcherFetchTermsAndConditions,
  watcherAcceptTermsAndConditions,
  watcherSaveUserInfo,
  watcherCreateDeepLink,
  watcherVerifyDeepLink,
  watcherSubmitRegister,
  watcherGenerateOtp,
  watcherVerifyOtp,
} from './registerSaga';
import {
  watcherAddProduct,
  watcherGetSurveysList,
  watcherSaveurveysList,
} from './accoutSaga'
import { watcherGetAccountDetail,watcherGenerateStatement } from './statementSaga'
import { showPopupAlert, hidePopupAlert } from '../components/common/AppProvider';

function handleError(error: any) {
  const MAX_LENGTH = 500;
  const errorString = JSON.stringify(error);

  const truncatedError = errorString.length > MAX_LENGTH
    ? errorString.substring(0, MAX_LENGTH) + '...'
    : errorString;

  return (truncatedError);
}


export function* handleStatusError(code: string, message: string) {

  yield call(showPopupAlert, {
    title: 'Error',
    subtitle: code,
    description: message,
    iconType: 1,
    ButtonText: 'OK',
    ButtonClick: () => {
      hidePopupAlert();
    },
  });
}

export default function* rootSaga() {
  yield all([
    watcherExchangeLineToken(),
    watcherFetchTermsAndConditions(),
    watcherAcceptTermsAndConditions(),
    watcherSaveUserInfo(),
    watcherCreateDeepLink(),
    watcherVerifyDeepLink(),
    watcherSubmitRegister(),
    watcherAddProduct(),
    watcherGetSurveysList(),
    watcherSaveurveysList(),
    watcherGenerateOtp(),
    watcherVerifyOtp(),
    watcherGetAccountDetail(),
    watcherGenerateStatement(),
  ]);
}
