import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { navigationRef } from '../utils/navigation';
import AccountDetailPage from '../features/account/pages/AccountDetailPage';
import AddAccountPage from '../features/account/pages/AddAccountPage';
import AddProductPage from '../features/account/pages/AddProductPage'
import ConfirmAccountPage from '../features/account/pages/ConfirmAccountPage';
import InfoPage from '../features/register/pages/InfoPage';
import RegisterPage from '../features/register/pages/KrungsriSimpleSerivePage';
import SurveyPage from '../features/success/pages/SurveyPage';
import TermsAndConditionsPage from '../features/register/pages/TermsAndConditionsPage';
import VerifyPage from '../features/register/pages/VerifyPage';
import SuccessPage from '../features/success/pages/SuccessPage';
import VerifyOTPPage from '../features/register/pages/VerifyOTPPage';
import ReceiveOTPPage from '../features/statement/pages/ReceiveOTPPage';
import RequestStatementPage from '../features/statement/pages/RequestStatementPage';
import PreStatementPage from '../features/statement/pages/preStatement';
import SuccessStatementPage from '../features/statement/pages/SuccessStatementPage';

const routes = [
  { path: "/terms-and-conditions", component: TermsAndConditionsPage, exact: true },
  { path: "/verify", component: VerifyPage, exact: true },
  { path: "/info", component: InfoPage, exact: true },
  { path: "/add-account", component: AddAccountPage, exact: true },
  { path: "/add-account/:account_no", component: AccountDetailPage, exact: true },
  { path: "/add-product", component: AddProductPage, exact: true },
  { path: "/confirm-account", component: ConfirmAccountPage, exact: true },
  { path: "/survey", component: SurveyPage, exact: true },
  { path: "/success", component: SuccessPage, exact: true },
  { path: "/verify-otp-page", component: VerifyOTPPage, exact: true },
  { path: "/receive-otp", component: ReceiveOTPPage, exact: true },
  { path: "/select-statement", component: RequestStatementPage, exact: true },
  { path: "/request-statement", component: PreStatementPage, exact: true },
  { path: "/success-statement", component: SuccessStatementPage, exact: true },
  { path: "*", component: RegisterPage, exact: true },
];

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkingFlow, setCheckingFlow] = useState(true);

  useEffect(() => {
    navigationRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accountRef = params.get('accountRef');
    if (accountRef) {
      sessionStorage.setItem('accountRef',accountRef)
    }
    setCheckingFlow(false);
  }, [location.search, navigate]);

  const renderRoutes = (routes: any[] = []) => (
    <Routes>
      {routes.map((route, i) => {
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={route.routes ? renderRoutes(route.routes) : <Component />}
          />
        );
      })}
    </Routes>
  );

  if (checkingFlow) {
    return null;
  }

  return renderRoutes(routes);
};

export default AppRoutes;