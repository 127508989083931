// Format phone number with format: 099-999-9999
const formatMobileNumber = (value: string): string => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = numericValue;
    if (numericValue.length > 3) {
        formattedValue = numericValue.slice(0, 3) + "-" + numericValue.slice(3);
    }
    if (numericValue.length > 6) {
        formattedValue = formattedValue.slice(0, 7) + "-" + formattedValue.slice(7);
    }
    return formattedValue.slice(0, 12);
};

// Format ID card with format: 1-2345-67890-12-3
const formatIdCard = (value: string): string => {
    const numericValue = value.replace(/\D/g, "");
        let formattedValue = numericValue;
        if (numericValue.length > 1) {
            formattedValue = numericValue.slice(0, 1) + "-" + numericValue.slice(1);
        }
        if (numericValue.length > 5) {
            formattedValue = formattedValue.slice(0, 6) + "-" + formattedValue.slice(6);
        }
        if (numericValue.length > 10) {
            formattedValue = formattedValue.slice(0, 12) + "-" + formattedValue.slice(12);
        }
        if (numericValue.length > 12) {
            formattedValue = formattedValue.slice(0, 15) + "-" + formattedValue.slice(15);
        }
        return formattedValue.slice(0, 17);
};

// Format account number with format: 123-4-52345-3
const formatAccountNumber = (value: string): string => {
    const numericValue = value.replace(/\D/g, "");
    let formattedValue = numericValue;

    if (numericValue.length > 3) {
        formattedValue = numericValue.slice(0, 3) + "-" + numericValue.slice(3);
    }
    if (numericValue.length > 4) {
        formattedValue = formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
    }
    if (numericValue.length > 9) {
        formattedValue = formattedValue.slice(0, 11) + "-" + formattedValue.slice(11);
    }
    return formattedValue.slice(0, 13);
};

const formatReferral = (value: string): string => {
    return value.slice(0, 50); 
};

// Export the formatting functions
export { formatMobileNumber, formatIdCard, formatAccountNumber, formatReferral };
