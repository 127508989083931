import { call, put, take } from 'redux-saga/effects';
import { apiGenerateStatement, apiGetAccountDetail } from "../api/api";
import { showSpinner, hideSpinner } from "../components/common/AppProvider";
import { handleStatusError } from "./rootSaga";
import { navigationRef } from '../utils/navigation';
import { GET_ACCOUNT_DETAIL, GENERATE_STATEMENT, setAccountDetail } from '../actions/statementAction';

function* workerGetAccountDetail(payload: any): Generator<any, void, any> {
    try {
        yield call(showSpinner);
        const response = yield call(apiGetAccountDetail);
        if (response.success) {
            yield put(setAccountDetail(response.data))
        } else {
            yield call(handleStatusError, '404', "can not fetch data");
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGetAccountDetail(): Generator<any, void, any> {
    while (true) {
        const action = yield take(GET_ACCOUNT_DETAIL);
        yield call(workerGetAccountDetail, action.payload);
    }
}

function* workerGenerateStatement(payload: any): Generator<any, void, any> {
    const { language, fromBookingDateTime, toBookingDateTime, email } = payload.statementDetail
    try {
        yield call(showSpinner);
        const response = yield call(
            apiGenerateStatement,
            language,
            fromBookingDateTime,
            toBookingDateTime,
            email,
        );
        if (response.success) {
            if (navigationRef.current) {
                navigationRef.current('/success-statement');
            } else {
                yield call(handleStatusError, '404', 'navigationRef.current is not set');
            }
        } else {
            yield call(handleStatusError, '404', "can not fetch data");
        }
    } catch (error) {
        yield call(handleStatusError, '404', JSON.stringify(error));
    } finally {
        yield call(hideSpinner);
    }
}

export function* watcherGenerateStatement(): Generator<any, void, any> {
    while (true) {
        const action = yield take(GENERATE_STATEMENT);
        yield call(workerGenerateStatement, action.payload);
    }
}