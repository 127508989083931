import { EXCHANGE_LINE_TOKEN, UPDATE_LKS_TOKEN } from '../actions/tokenAction';
import { RootState } from '../store/store';
import { createSelector } from 'reselect';

export interface TokensState {
  lineToken?: string;
  lksToken?: string;
}

export interface TokensAction {
  type: string;
  payload: any;
}

const initialState: TokensState = {
  lineToken: '',
  lksToken: '',
};

export const tokenReducer = (state: TokensState = initialState, action: TokensAction): TokensState => {
  switch (action.type) {
    case EXCHANGE_LINE_TOKEN:
      return {
        ...state,
        lineToken: action.payload,
      };

    case UPDATE_LKS_TOKEN:
      return {
        ...state,
        lksToken: action.payload.lksToken,
      };

    default:
      return state;
  }
};

const selectTokens = (state: RootState) => state.tokens;

export const tokenSelector = createSelector(
  [selectTokens],
  (tokens: TokensState) => ({
    lineToken: tokens?.lineToken ?? '',
    lksToken: tokens?.lksToken ?? '',
  })
);