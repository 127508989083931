export const EXCHANGE_LINE_TOKEN = 'EXCHANGE_LINE_TOKEN'
export const UPDATE_LKS_TOKEN = 'UPDATE_LKS_TOKEN'

export const exchangeLineToken = (lineToken: string, nextPage: string) => ({
  type: EXCHANGE_LINE_TOKEN,
  payload: { lineToken, nextPage },
});

export const updateLksToken = (lksToken: string) => ({
  type: UPDATE_LKS_TOKEN,
  payload: { lksToken },
});