import ToggleSwitch from "./ToggleSwitch";
import { Description } from '../common/Texts';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { JSX } from "react/jsx-runtime";

type AccountInfoProps = {
  accountNo: string;
  type: string;
  isNotificationEnabled: boolean;
  status: string;  
  isKMA: boolean;
  onToggle: () => void 
}

export default function AccountInfo({ accountNo, type, isNotificationEnabled, status, isKMA, onToggle }: AccountInfoProps) {
  const navigate = useNavigate();
  const goToNextPage = () => {
    navigate(`/add-account/${accountNo}`);
  };

  function maskAccountNumber(accountNumber: string): string {
    if (accountNumber.length !== 10) {
      throw new Error('Account number must be exactly 10 digits');
    }
    return `XXX-${accountNumber[3]}-${accountNumber.slice(4, 8)}-X`;
  }
  
  function matchTypeAccount(type: string): string {
    const typeToRoleMap: { [key: string]: string } = {
      'SVNG': 'บัญชีออมทรัพย์',
      'CURR': 'บัญชีกระแสรายวัน',
      'TD': 'บัญชีเงินฝากประจำ',
    };
  
    return typeToRoleMap[type] || 'เงินฝากออมทรัพย์';
  }

  const accountIcon = isKMA ? '/svg/passbook.svg' : '/svg/accBook.svg'

  return (
    <div style={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #E8E8E8',
    }}>
      <img
        src={accountIcon}
        alt="ks-passbook"
        style={{
          width: '40px',
          height: '40px',
        }}
      />
      <div style={{
        flexGrow: 1,
        paddingLeft: '10px',
        paddingRight: '13px',
        paddingBottom: '8px',
        paddingTop: '8px',
      }}>
        <Description>{matchTypeAccount(type)}</Description>
        <Description>{maskAccountNumber(accountNo)}</Description>
      </div>
      <button
        onClick={goToNextPage}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          fontSize: '16px',
          fontWeight: 'bold',
          padding: '0 10px',
        }}
      >
        <div style={
          {
            position: 'absolute',
            left: '180px',
            top: '13px',
            fontFamily: "Font Awesome 5 Pro",
            color: '#5A5A5A',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
          }
        }>
          <img
            src={'/svg/chevronRight.svg'}
            alt="switch"
          />
        </div>
      </button>
      {
        status === 'Enable' ?
          <ToggleSwitch isToggled={isNotificationEnabled} onToggle={onToggle}/>:
        <div style={{fontSize: '16px', fontFamily: 'Thongterm_Medium',color:"#5A5A5A"}}>
          {status}
        </div>
      }
      
    </div>
  );
}
